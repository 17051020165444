import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { FourOhFourView } from "components/views/FourOhFourView/FourOhFourView"

interface FourOhFourProps {
  location: WindowLocation
}

const FourOhFour = ({ location }: FourOhFourProps): JSX.Element => {
  return (
    <>
      <Seo pathname={location.pathname} title="Not Found" is404 />
      <FourOhFourView />
    </>
  )
}

export default FourOhFour
