import { Icon, PrimaryButton, VerticalSpacing } from "@einride/ui"
import { ContentWrapper } from "components/shared/styles/Layout"
import { Layout } from "components/ui/Layout/Layout"
import { Link } from "components/ui/Link/Link"
import { Title1 } from "lib/ui/Typography/Typography"

export const FourOhFourView = (): JSX.Element => {
  return (
    <Layout>
      <VerticalSpacing size="xl" />
      <ContentWrapper>
        <Title1>Page not found.</Title1>
        <VerticalSpacing size="lg" />
        <Link to="/">
          <PrimaryButton rightIcon={<Icon name="arrowRight" />}>Return to home</PrimaryButton>
        </Link>
      </ContentWrapper>
      <VerticalSpacing size="xl" />
    </Layout>
  )
}
